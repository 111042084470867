import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Navbar, {buttonParser} from './components/navbar';
import reportWebVitals from './reportWebVitals';
import data from '../src/components/navbar/data.json'
import './scss/main.scss'
import CookieModal from './components/cookieModal/index'

const buttonProps = {
    "data": [
      {
        "attributes": {
          "name": "requestDemoButton",
          "Button": {
            "label": "Request a demo",
            "width": "auto",
            "type": "main",
            "icon": "none"
          },
          "action": "redirect",
          "actionValue": "/demo",
          "formConfig": null
        }
      }
    ]

}


const root = ReactDOM.createRoot(
  document.getElementById('pdiv') as HTMLElement
);

function Nav(){
  const [navHeight, setNavbarHeight] = React.useState(80)
const [closeHelloBarStatus, setCloseHelloBarStatus] = React.useState(true)

const [showHelloBar, setShowHelloBar] = useState(false)
const [helloBarDisplay, setHelloBarDisplay] = useState('none')

  return <>
  
  <Navbar navData={data} navBarHeight={navHeight}
  helloBarStatus={helloBarDisplay}
  buttons={buttonParser.parseButton(buttonProps, null)}
  />

  <CookieModal></CookieModal>
  
  
  </>
}

root.render(
  <Nav/>
);