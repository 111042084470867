import { useEffect } from 'react'
import {Button} from '../../button/button'
import Accordion from './accordion'
import ToggleSwitch from './toggleSwitch'
interface CookieControl {
	name: string
	value: boolean
}

const CookiePreferencesTable = (props: any) => {
	if (props.tableContent.length) {
		return (
			<table className="body-s">
				<thead>
					<tr>
						<th>Cookie Subgroups</th>
						<th>Cookies</th>
						<th>Cookies Used</th>
						<th>Lifespan</th>
					</tr>
				</thead>
				<tbody>
					{props.tableContent.map((row: any, index: number) => {
						return (
							<tr key={index}>
								<td>{row.cookieSubgroups}</td>
								<td>{row.cookies}</td>
								<td>{row.cookiesUsed}</td>
								<td>{row.lifespan} Days</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		)
	} else {
		return <></>
	}
}

const cookieControlValue: any = {}

const CookiePreferences = (props: any) => {
	const updateCookieControl = (cookieControl: CookieControl) => {
		cookieControlValue[cookieControl.name] = cookieControl.value
	}
	const onConfirmChoices = (e: any) => {
		props.onSelectPreferences(cookieControlValue)
	}

	const convertToDisplayString = (text: string) => {
		const result = text.replace(/([A-Z])/g, ' $1')
		const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
		return finalResult
	}

	useEffect(() => {
		props.cookieCategoriesData &&
			props.cookieCategoriesData.forEach((cookie: any) => {
				if (cookie.cookieCategory === 'strictlyNecessaryCookies') {
					cookieControlValue[cookie.cookieCategory] = true
				} else {
					cookieControlValue[cookie.cookieCategory] = false
				}
			})
	}, [props.cookieCategoriesData])

	return (
		<div className="modal">
			<div className="z-contain modal-container">
				<div className="border">
					<svg
						className="fractal"
						xmlns="http://www.w3.org/2000/svg"
						width="51"
						height="40"
						viewBox="0 0 51 40"
						fill="none">
						<path
							d="M32.5664 0H0L19.115 40H50.6195L32.5664 0Z"
							fill="#A1DBE4"
						/>
					</svg>
					<i
						className="material-icons-round cancel-button"
						style={{ display: 'block' }}
						onClick={() => {
							props.onCloseModal()
						}}>
						close
					</i>
					<div className="z-row">
						<div className="z-item-12 logo">
							<img
								alt="logo"
								src="https://storage.googleapis.com/infinity_bucket_static/staticfiles/image/site-logo/innovaccer-logo-black.svg"
								width={142}
								height={24}
							/>
						</div>
					</div>
					<div className="z-row modal-contents scroller">
						<h5 className="z-item-12 heading">Cookie Preferences</h5>
						<div className="body-xs -modern z-item-12 message">
							Innovaccer uses cookies to improve your experience and for
							marketing. Review and manage your cookie settings below to control
							your privacy. For more information on how we use cookies, please
							see&nbsp;
							<a
								className="cookie-policy  -link"
								target="_blank"
								href="/privacy#cookies-tracking">
								Innovaccer’s Cookie Policy.
							</a>
						</div>
						<h5 className="z-item-12 sub-heading">Manage Cookies</h5>
						{props.cookieCategoriesData &&
							props.cookieCategoriesData.map((cookie: any, index: number) => {
								return (
									<div key={index} className="z-item-12 cookie-item">
										<Accordion
											heading={convertToDisplayString(cookie.cookieCategory)}
											actionElement={
												cookie.cookieCategory === 'strictlyNecessaryCookies' ? (
													<h6 className="cookie-item-tag">Always Active</h6>
												) : (
													<ToggleSwitch
														updateCookieControl={updateCookieControl}
														controlName={cookie.cookieCategory}
													/>
												)
											}
											cookiesDetail={
												<CookiePreferencesTable tableContent={cookie.cookies} />
											}>
											{cookie.cookieDescription}
										</Accordion>
									</div>
								)
							})}
					</div>
					<div className="z-row preference-buttons">
						<div className="disable-all-btn z-item-6">
							<Button
								type={'outline'}
								icon={'none'}
								width={'100%'}
								label={'Disable Optional Cookies'}
								onClick={() => {
									props.disableAllCookies()
								}}
							/>
						</div>
						<div className="confirm-choices-btn z-item-6">
							<Button
								type={'main'}
								icon={'none'}
								width={'100%'}
								label={'Confirm my choices'}
								onClick={onConfirmChoices}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CookiePreferences
