import React, { useRef, useState } from 'react'
const ToggleSwitch = (props: any) => {
	const checkboxRef = useRef<HTMLInputElement>(null)
	const [switchLabel, setSwitchLabel] = useState('Disabled')
	const isChecked = () => {
		return checkboxRef.current!.checked
	}
	const toggleCheckbox = (e: any) => {
		if (isChecked()) {
			props.updateCookieControl({ name: props.controlName, value: true })
			setSwitchLabel('Enabled')
		} else {
			props.updateCookieControl({ name: props.controlName, value: false })
			setSwitchLabel('Disabled')
		}
	}
	return (
		<div className="toggle-switch">
			<h6
				className={`switch-label ${
					switchLabel === 'Enabled' ? 'switch-enabled' : 'switch-disabled'
				}`}>
				{switchLabel}
			</h6>
			<label className="switch">
				<input type="checkbox" ref={checkboxRef} onClick={toggleCheckbox} />
				<span className="slider"></span>
			</label>
		</div>
	)
}

export default ToggleSwitch
