import { useState, useRef } from 'react'
const Accordion = (props: any) => {
	const [isActive, setIsActive] = useState(false)
	const panelRef = useRef<HTMLDivElement>(null)
	const toggleAccordion = (e: any) => {
		setIsActive((prevState) => !prevState)
		const panel = panelRef.current
		if (panel!.style.maxHeight) {
			panel!.style.maxHeight = ''
		} else {
			panel!.style.maxHeight = panel!.scrollHeight + 'px'
		}
	}
	return (
		<div className="accordion-container" style={props.style}>
			<div className="accordion-head-section">
				<h6
					className={`accordion dropdown-toggle ${isActive && 'active'}`}
					onClick={toggleAccordion}>
					{props.heading}
				</h6>
				{props.actionElement}
			</div>
			<div
				className={`panel body-xs -modern ${isActive && 'open'}`}
				ref={panelRef}>
				<p style={{ width: '70%' }}>{props.children}</p>
				<div className="cookies-detail-container">{props.cookiesDetail}</div>
			</div>
		</div>
	)
}
export default Accordion
