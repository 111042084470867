import {
	ApolloClient,
	createHttpLink,
	HttpLink,
	InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({
	uri: 'https://innovaccer.com/graphql',
	credentials: 'include',
})

const authLink = setContext((_, { headers }) => {
	return {
		headers: {
			...headers,
		},
	}
})

const clientForm = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
})

export default clientForm
