//import _ from 'lodash'
import cx from 'classnames'
import { useEffect, useState, useRef } from 'react'
import Router from 'next/router'

const NavMob = ({ navData }: any) => {
	const dropdownRef = useRef(null);
	const [activeMenu, setActiveMenu] = useState('')
	const [currentIdx, setCurrentIdx] = useState(1)
	const [toggleSubMenu, setToggleSubMenu] = useState(false)
	const [secondLevelToogle, setSecondLevelToogle] = useState(false)
	const [currentDropdown, setCurrentDropDown] = useState('')
	useEffect(() => {
		if (navData) {
			setActiveMenu(navData[0].attributes.headerName)
		}

	}, [navData])


	const redirectToPage = (url: string) => {
		window.location.href = url
	}
	const handleDropDown = (id: any) => {

		const allDropdowns: any = document.querySelectorAll('.drop-down')

		for (let i = 0; i < allDropdowns.length; i++) {

			if (allDropdowns[i].id !== id) {

				allDropdowns[i].style.display = 'none';
				const up: any = document.getElementById('up' + allDropdowns[i].id)
				if (up)
					up.style.display = 'none'
				const down: any = document.getElementById('down' + allDropdowns[i].id)
				if (down)
					down.style.display = 'inline-block'
			}
			else {

				if (allDropdowns[i].style.display = allDropdowns[i].style.display === 'flex') {

					allDropdowns[i].style.display = allDropdowns[i].style.display = 'none'
					const up: any = document.getElementById('up' + allDropdowns[i].id)
					if (up)
						up.style.display = 'none'
					const down: any = document.getElementById('down' + allDropdowns[i].id)
					if (down)
						down.style.display = 'inline-block'
				}
				else {

					allDropdowns[i].style.display = allDropdowns[i].style.display = 'flex';
					const up: any = document.getElementById('up' + id)
					if (up)
						up.style.display = 'inline-block'
					const down: any = document.getElementById('down' + id)
					if (down)
						down.style.display = 'none'
				}

			}
		}
	}
	return (
		<div id="mobNav">
			{navData.map((navEle: any, idx: any) => (
				<li key={navEle.id} className="dropdown">
					{!toggleSubMenu && (
						<>
							<div
								key={idx + 1}
								className={cx(
									'navLink fs-16 lh-22 d-flex justify-space-between',
									{
										activeSubMenu: activeMenu === navEle.attributes.headerName,
									}
								)}
								onClick={(e) => {
									setActiveMenu(navEle.attributes.headerName)
									setCurrentIdx(navEle.id)
									setToggleSubMenu(true)
								}}>
								<div className="fs-16 lh-24">
									{navEle.attributes.headerName}
								</div>
								<div
									className="arrow"
									style={{
										borderLeft:
											activeMenu === navEle.attributes.headerName
												? '8px solid #ffffff'
												: '8px solid #19202e',
									}}></div>
							</div>
							{navEle.id != currentIdx && (
								<div className="pl-25 pr-25">
									<hr className="mb-0 mt-0" />
								</div>
							)}
						</>
					)}
					{!navEle.attributes.withSubNav &&
						toggleSubMenu &&
						activeMenu === navEle.attributes.headerName && (
							<>
								<div
									className="p-24 grey--700"
									style={{ background: '#F3F3F3' }}
									onClick={() => setToggleSubMenu(false)}>
									<span
										className="material-icons"
										style={{ verticalAlign: 'middle' }}>
										arrow_back
									</span>
									<span className="ml-12 text-uppercase font-dark">
										{navEle.attributes.title}
									</span>
								</div>
								{navEle.attributes.menuItems[0].allNavs.map((ele: any) => (
									<div key={ele.id}>
										{
											ele.multi ?

												<>
													<div
														key={ele.id}
														className="navLink-dropdown fs-16 lh-22"

														onClick={(e) => {
															handleDropDown(ele.head + ele.id)
														}}
													>
														<div className="fs-16 lh-24">{ele.head}</div>
														<span className='dropdown-icon'
															id={'up' + ele.head + ele.id}
														><svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M4.69763 0.682462C4.85714 0.498277 5.14286 0.498277 5.30237 0.682462L9.18995 5.17145C9.4143 5.43051 9.23028 5.83331 8.88758 5.83331L1.11242 5.83331C0.76972 5.83331 0.585699 5.43051 0.810049 5.17145L4.69763 0.682462Z" fill="#19202E"></path></svg></span>

														<span id={'down' + ele.head + ele.id}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
															<path d="M5.30237 5.31754C5.14286 5.50172 4.85714 5.50172 4.69763 5.31754L0.810049 0.828548C0.585698 0.569491 0.76972 0.166687 1.11242 0.166687L8.88758 0.166687C9.23028 0.166687 9.4143 0.569491 9.18995 0.828548L5.30237 5.31754Z" fill="#121010" />
														</svg></span>

													</div>
													<div className="pl-25 pr-25">
														<hr className="mb-0 mt-0" />
													</div>
													<div className='drop-down' id={ele.head + ele.id}

													>
														{
															ele?.multi_links.map((elem: any, inx: number) => (
																<a key={inx}
																	href={elem.url}

																>
																	<div
																		className='drop-data'
																	>
																		{/* <img
																			className='drop-icon'
																			src={elem.icon.data.attributes.url}
																		/> */}

																		<span>
																			{elem.title}
																		</span>
																	</div>
																	<div className="pl-25 pr-25">
																		<hr className="mb-0 mt-0" />
																	</div>

																</a>
															))
														}

													</div>
												</>
												:
												<>
													<div
														key={ele.id}
														className="navLink fs-16 lh-22 d-flex justify-space-between"
														onClick={(e) => {
															redirectToPage(ele.url)
														}}>
														<div className="fs-16 lh-24">{ele.head}</div>
														<div
															className="arrow"
															style={{
																borderLeft:
																	activeMenu === navEle.attributes.headerName
																		? '8px solid #ffffff'
																		: '8px solid #19202e',
															}}></div>
													</div>
													<div className="pl-25 pr-25">
														<hr className="mb-0 mt-0" />
													</div>
												</>

										}

									</div>
								))}
							</>
						)}
					{navEle.attributes.withSubNav &&
						toggleSubMenu &&
						!secondLevelToogle &&
						activeMenu === navEle.attributes.headerName && (
							<>
								<div
									className="p-24 grey--700"
									style={{ background: '#F3F3F3' }}>
									<span
										onClick={() => setToggleSubMenu(false)}
										className="material-icons"
										style={{ verticalAlign: 'middle' }}>
										arrow_back
									</span>
									<span className="ml-12 text-uppercase font-dark">
										{navEle.attributes.title}
									</span>
								</div>
								{navEle.attributes.menuItems.map((ele: any) => (
									<div key={ele.id}>
										<div
											key={ele.id}
											className="navLink fs-16 lh-22 d-flex justify-space-between"
											onClick={(e) => {
												setActiveMenu(ele.name)
												setSecondLevelToogle(true)
											}}>
											<div className="fs-16 lh-24">{ele.name}</div>
											<div
												className="arrow"
												style={{
													borderLeft: '8px solid #19202e',
												}}></div>
										</div>
										<div className="pl-25 pr-25">
											<hr className="mb-0 mt-0" />
										</div>
									</div>
								))}
							</>
						)}
					{secondLevelToogle && (
						<>
							{navEle.attributes.menuItems.map((ele: any) => (
								<div key={ele.id}>
									{activeMenu === ele.name && (
										<>
											<div
												className="p-24 grey--700"
												style={{ background: '#F3F3F3' }}>
												<span
													onClick={() => {
														setToggleSubMenu(true)
														setSecondLevelToogle(false)
														setActiveMenu(navEle.attributes.headerName)
													}}
													className="material-icons"
													style={{ verticalAlign: 'middle' }}>
													arrow_back
												</span>
												<span className="ml-12 text-uppercase font-dark">{`${activeMenu}`}</span>
											</div>
											{ele.allNavs.map((subNav: any, key: string) => (

												<div key={key}>
													{
														subNav.multi ?

															<>

																<div
																	key={key}
																	className="navLink-dropdown fs-16 lh-22"

																	onClick={(e) => {
																		handleDropDown(subNav.head + key)
																	}}

																>
																	<div className="fs-16 lh-24 name">{subNav.head}</div>
																	<span className="dropdown-icon" id={'up' + ele.head + key}>
																		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M4.69763 0.682462C4.85714 0.498277 5.14286 0.498277 5.30237 0.682462L9.18995 5.17145C9.4143 5.43051 9.23028 5.83331 8.88758 5.83331L1.11242 5.83331C0.76972 5.83331 0.585699 5.43051 0.810049 5.17145L4.69763 0.682462Z" fill="#19202E"></path></svg>
																	</span>
																	<span id={'down' + ele.head + key}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
																		<path d="M5.30237 5.31754C5.14286 5.50172 4.85714 5.50172 4.69763 5.31754L0.810049 0.828548C0.585698 0.569491 0.76972 0.166687 1.11242 0.166687L8.88758 0.166687C9.23028 0.166687 9.4143 0.569491 9.18995 0.828548L5.30237 5.31754Z" fill="#121010" />
																	</svg></span>

																</div>
																<div className="pl-25 pr-25">
																	<hr className="mb-0 mt-0" />
																</div>
																<div className='drop-down' id={subNav.head + key}

																>

																	{
																		subNav?.multi_links?.map((elem: any, inx: number) => (
																			<a key={inx}
																				href={elem.url}

																			>
																				<div
																					className='drop-data'
																				>
																					{/* <img
																						className='drop-icon'
																						src={elem.icon.data.attributes.url}
																					/> */}

																					<span>
																						{elem.title}
																					</span>
																				</div>

																				<div className="pl-25 pr-25">
																					<hr className="mb-0 mt-0" />
																				</div>

																			</a>
																		))
																	}

																</div>
															</>
															:
															<>

																<div
																	key={ele.id}
																	className="navLink fs-16 lh-22 d-flex justify-space-between"
																	onClick={(e) => {
																		redirectToPage(subNav.url)
																	}}>
																	<div className="fs-16 lh-24">{subNav.head}</div>
																</div>
																<div className="pl-25 pr-25">
																	<hr className="mb-0 mt-0" />
																</div>
															</>

													}
												</div>

											))}
										</>
									)}
								</div>
							))}
						</>
					)}
				</li>
			))}
		</div>
	)
}

export default NavMob
