import React from 'react'
import PropTypes from 'prop-types'

const Bullet = ({ link, size }) => {
	const sizeChart = { l: 24, m: 20, s: 16, xs: 14 }
	return (
		<>
			{link ? (
				<svg
					width={sizeChart[size]}
					height={sizeChart[size]}
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<mask
						id="mask0_303_236"
						style={{ maskType: 'alpha' }}
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="24"
						height="24"
					>
						<rect width="24" height="24" fill="#D9D9D9" />
					</mask>
					<g mask="url(#mask0_303_236)">
						<path
							d="M4.6892 18.7358C4.5131 18.5597 4.42505 18.3356 4.42505 18.0635C4.42505 17.7913 4.5131 17.5672 4.6892 17.3911L15.1592 6.9211H9.78011C9.50795 6.9211 9.27998 6.8292 9.0962 6.64542C8.91177 6.46099 8.81956 6.2327 8.81956 5.96055C8.81956 5.68839 8.91177 5.4601 9.0962 5.27568C9.27998 5.09189 9.50795 5 9.78011 5H17.4645C17.7367 5 17.9646 5.09189 18.1484 5.27568C18.3328 5.4601 18.425 5.68839 18.425 5.96055V13.6449C18.425 13.9171 18.3328 14.1451 18.1484 14.3289C17.9646 14.5133 17.7367 14.6055 17.4645 14.6055C17.1923 14.6055 16.9644 14.5133 16.7806 14.3289C16.5962 14.1451 16.504 13.9171 16.504 13.6449V8.26587L6.03397 18.7358C5.85787 18.9119 5.63374 19 5.36158 19C5.08943 19 4.8653 18.9119 4.6892 18.7358Z"
							fill="#19202E"
						/>
					</g>
				</svg>
			) : (
				// <svg
				// 	width={sizeChart[size]}
				// 	height={sizeChart[size]}
				// 	viewBox="0 0 24 24"
				// 	fill="none"
				// 	xmlns="http://www.w3.org/2000/svg"
				// >
				// 	<path
				// 		d="M9.55012 17.5749C9.41679 17.5749 9.29179 17.5539 9.17512 17.5119C9.05846 17.4706 8.95012 17.3999 8.85012 17.2999L4.55012 12.9999C4.36679 12.8166 4.27912 12.5789 4.28712 12.2869C4.29579 11.9956 4.39179 11.7582 4.57512 11.5749C4.75846 11.3916 4.99179 11.2999 5.27512 11.2999C5.55846 11.2999 5.79179 11.3916 5.97512 11.5749L9.55012 15.1499L18.0251 6.6749C18.2085 6.49157 18.4461 6.3999 18.7381 6.3999C19.0295 6.3999 19.2668 6.49157 19.4501 6.6749C19.6335 6.85824 19.7251 7.09557 19.7251 7.3869C19.7251 7.6789 19.6335 7.91657 19.4501 8.0999L10.2501 17.2999C10.1501 17.3999 10.0418 17.4706 9.92512 17.5119C9.80846 17.5539 9.68346 17.5749 9.55012 17.5749Z"
				// 		fill="#19202E"
				// 	/>
				// </svg>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="8"
					height="8"
					viewBox="0 0 8 8"
					fill="none"
				>
					<circle cx="4" cy="4" r="4" fill="#E31C79" />
				</svg>
			)}
		</>
	)
}

Bullet.propTypes = {
	link: PropTypes.bool.isRequired,
	size: PropTypes.oneOf(['l', 'm', 's', 'xs']),
}

export default Bullet
