
import React from 'react'
import PropTypes from 'prop-types'
import ButtonLink from './buttonLink'
import PlayButton from './playButton'
import './button.scss'

export const Button = ({ label, type, icon, onClick, width }) => {
	const displayleft =
		icon === 'left' ? 'inline-block' : icon === 'both' ? 'inline-block' : 'none'
	const displayright =
		icon === 'right'
			? 'inline-block'
			: icon === 'both'
			? 'inline-block'
			: 'none'
	return type === 'link' ? (
		<ButtonLink label={label} onClick={onClick} />
	) : type === 'play_outline' ? (
		<PlayButton type={'outline'} label={label} onClick={onClick} />
	) : type === 'play_soft' ? (
		<PlayButton type={'soft'} label={label} onClick={onClick} />
	) : (
		<div className="Button">
			<button
				type="submit"
				onClick={onClick}
				className={['button', `button-type--${type}`, `padding--${icon}`].join(
					' '
				)}
				style={{ width: width }}
			>
				<i
					className="material-icons-round icon-left"
					style={{ display: displayleft }}
				>
					play_arrow
				</i>

				{label}
				<i
					className="material-icons-round icon-right"
					style={{ display: displayright }}
				>
					play_arrow
				</i>
			</button>
		</div>
	)
}

Button.propTypes = {
	type: PropTypes.oneOf([
		'main',
		'soft',
		'outline',
		'link',
		'play_soft',
		'play_outline',
	]),
	icon: PropTypes.oneOf(['left', 'right', 'both', 'none']),
	width: PropTypes.oneOf(['auto', '100%']),
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
}

// Button.defaultProps = {
// 	type: 'main',
// 	icon: 'none',
// 	width: 'auto',
// 	label: 'Button',
// 	onClick: undefined,
// }

export default Button
