import { gql } from '@apollo/client'

const trackerMeta = gql`
	query {
		trackerMetas(sort: "publishedAt:desc") {
			data {
				attributes {
					cookieCategory
					cookieDescription
					region
					cookies {
						cookieSubgroups
						cookies
						cookiesUsed
						lifespan
					}
				}
			}
		}
	}
`
export default trackerMeta
