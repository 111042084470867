// import '../../styles/index.scss'
import React, { createContext, useEffect, useState } from 'react'
import tracker from './tracker'
import CookieModal2 from './cookieModal2'
import CookiePreferences from './cookiePreferences'
import { parseCookies, setCookie } from 'nookies'
import mountTrackers, {
	getCookieControlData,
	getTrackerMeta,
} from './trackerData'


import dynamic from 'next/dynamic'


import _ from 'lodash'
import cookie from './utils/cookie'
import './cookies'


const getCookie = (): { [key: string]: string } => {
	return parseCookies()
}

let oldScrollY = 0
let cookieControlValue: any = {}
let cookieCategoriesData: any[]
const setCookieControl = (key: string, value: any) => {
	setCookie(null, key, value, {
		maxAge: 60 * 24 * 60 * 60,
		path: '/',
	})
}
export const helloBarContext = createContext<any>(null);

export default function CookieModal() {
    let region = 'default';
    const [showCookieModal, setShowCookieModal] = React.useState<boolean>(false)
    const [showCookiePreferences, setShowCookiePreferences] =
		React.useState<boolean>(false)
        const [popupStatus, setPopupStatus] = React.useState(false)
        const [isClosed, setIsClosed] = React.useState(false)

        const handleClose = () => {
            setIsClosed(true)
            setPopupStatus(false)
            // if (popupData.removeOnCross) {
            //     cookie.appendCookieClosedPopus(popupData.id)
            // }
        }

        const getCookieKeyName = () => {
            return "cookieControl" + region.charAt(0).toUpperCase() + region.slice(1).toLowerCase();
        }

        const acceptAllCookies = (e: any) => {
            for (const [key, value] of Object.entries(cookieControlValue)) {
                cookieControlValue[key] = true
            }
            setCookieControl(getCookieKeyName(), JSON.stringify(cookieControlValue))
            setShowCookieModal(false)
            mountTrackers(cookieControlValue).then((res) => {
                console.log(cookieControlValue)
                // ga.pageView(getURLForGA(), pageProps.trackerData[0].attributes.trackerID)
            })
        }

        const rejectAllCookies = (e: any) => {
            setCookieControl(getCookieKeyName(), JSON.stringify(cookieControlValue))
            showCookieModal && setShowCookieModal(false)
            showCookiePreferences && setShowCookiePreferences(false)
        }

        const userCookiePreference = (userCookieControlValue: any) => {
            cookieControlValue = userCookieControlValue
            mountTrackers(cookieControlValue).then((res) => {
            	// ga.pageView(getURLForGA(), pageProps.trackerData[0].attributes.trackerID)
            })
            setCookieControl(getCookieKeyName(), JSON.stringify(cookieControlValue))
            showCookiePreferences && setShowCookiePreferences(false)
        }

        const onCloseCookiePreference = (e: any) => {
            setShowCookiePreferences(false)
            setShowCookieModal(true)
        }

        useEffect(() => {
            const fetchDataAndSetPreferences = async (region: string) => {
                const cookieKey = `cookieControl${region.charAt(0).toUpperCase() + region.slice(1)}`;
                console.log(cookieKey)
                const { [cookieKey]: cookieControlData } = getCookie();
                console.log([cookieKey]);
    
                if (!cookieControlData) {
                    const cookieCategories = await getCookieControlData(region);
                    cookieCategories.forEach((cookieCategory: string) => {
                        cookieControlValue[cookieCategory] = false;
                    });
                    setShowCookiePreferences(false);
                    setShowCookieModal(true);
                } else {
                    cookieControlValue = JSON.parse(cookieControlData);
                    mountTrackers(cookieControlValue).then((res) => {
                        // ga.pageView(
                        //     getURLForGA(),
                        //     pageProps.trackerData[0].attributes.trackerID
                        // )
                    });
                }
            };
    
            if (region === 'default' || region === 'me' || region === 'uk') {
                fetchDataAndSetPreferences(region);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [region]);

        useEffect(() => {


            const fetchDataAndSetPreferences = async (region: string) => {
                const cookieKey = `cookieControl${region.charAt(0).toUpperCase() + region.slice(1)}`;
                const { [cookieKey]: cookieControlData } = getCookie();
    
                if (cookieControlData) {
                    const originalPath = window.location.pathname
                    let variant: any
                    
                    // mountTrackers(cookieControlValue).then((res) => {
                    // 	// ga.pageView(
                    // 	// 	variant ? originalPath + `?variant=${variant}` : originalPath,
                    // 	// 	pageProps.trackerData[0].attributes.trackerID
                    // 	// )
                    // 	// if (router.query?.pdf) {
                    // 	// 	ga.event('opened_pdf', 'PDF', window.location.href)
                    // 	// }
                    // })
                    
                }
    
            };
    
            if (region === 'default' || region === 'me' || region === 'uk') {
                fetchDataAndSetPreferences(region);
            }
        }, [region])

        const showPreferencesModal = (e: any) => {
            getTrackerMeta(region).then((res) => {
                cookieCategoriesData = res
                setShowCookieModal(false)
                setShowCookiePreferences(true)
            })
        }


        return <>
        <section
						id="cookie-modal"
						style={{
							display: showCookieModal ? 'block' : 'none',
						}}>
						<CookieModal2
							onAcceptAll={acceptAllCookies}
							onSelectPreferences={showPreferencesModal}
							onSelectDefaultPreference={rejectAllCookies}
						/>
					</section>
					<section
						id="cookie-preferences"
						style={{
							display: showCookiePreferences ? 'block' : 'none',
						}}>
						<CookiePreferences
							cookieCategoriesData={cookieCategoriesData}
							onCloseModal={onCloseCookiePreference}
							onSelectPreferences={userCookiePreference}
							disableAllCookies={rejectAllCookies}
						/>
					</section>
        </>

        
}