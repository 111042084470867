import { gql } from '@apollo/client'

const cookieControl = gql`
	query {
		trackerMetas {
			data {
				attributes {
					cookieCategory
					region
				}
			}
		}
	}
`
export default cookieControl
