
import isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react'
import NavDesktop from './navDesktop'
import NavMob from './navMob'
import Button from '../../components/button/button'
import './navbar.scss'

const buttonCreator = (
	buttonProps: any,
	setformvalues: any,
	submitActionHandler: any = undefined,
	buttonId: any = '',
	userMeta: any = {},
	formMeta: any = {}
) => {
	const buttonHandler = async () => {
		let action = buttonProps.action
		let url: string = ''
		const formName = buttonId + '#@' + buttonProps.name
		
		let mixpanelData
		
		if (typeof window !== 'undefined') {
			url = window.location.href
		}
		
		
		
		

		if (action === 'redirect') {
			if (typeof window === 'undefined') {
				return
			}

			const redirectURL = buttonProps.actionValue
			if (redirectURL == '/demo') {
				// const redirectToSpecificDemo = redirectURL + window.location.pathname
				window.open(redirectURL, '_self')
			} else {
				window.open(buttonProps.actionValue, '_self')
			}
		}
		
	}
	let _button = { ...buttonProps?.Button }
	if (_button?.width === 'full') {
		_button.width = '100%'
	}
	_button['buttonHandler'] = buttonHandler
	return _button
}

export const buttonParser = {
	parseButton: (
		form: any,
		setFormValues: any,
		submitActionHandler: any = undefined
	) => {
		let allButtons: any = []
		if (form?.data?.attributes) {
			const buttonConfig = buttonCreator(
				form.data.attributes,
				setFormValues,
				submitActionHandler,
				form.data.id
			)
			return buttonConfig
		} else if (form?.data && form?.data[0]?.attributes) {
			form.data.map((formsEle: any) => {
				const buttonConfig = buttonCreator(
					formsEle.attributes,
					setFormValues,
					submitActionHandler,
					formsEle.id
				)
				allButtons.push(buttonConfig)
			})
		}
		return allButtons
	},
}

const Navbar = (navData: any) => {

	const [toggleIcon, setToggleIcon] = useState(false)
	const [screenSize, setScreenSize] = useState<any>()
	const [style, setStyle] = useState<any>()
	const showNavControl =  '/'
	const [urlPath, setUrlPath] = useState<any>('')
	const [topHeight, setTopHeight] = useState(navData.navBarHeight)

	const setDimension = () => {
		setScreenSize({
			innerHeight: window.innerHeight,
		})
		setStyle({
			marginTop: `${window.innerHeight - 500}px`,
		})
	}

	const redirectToHomepage = () => {
		if (navData.navData[0]?.attributes.region === 'uk') {
			return "/uk"
		}
		else if (navData.navData[0]?.attributes.region === 'me') {
			return "/middle-east"
		} else {
			return "/"
		}
		return '/'
	}
	const redirectToFooter = () => {
		const footerEle = document.getElementById('footer')
		if (footerEle) {
			footerEle.scrollIntoView({ block: 'center', behavior: 'smooth' });
		}
	}

	useEffect(() => {
		if (isEmpty(screenSize)) {
			setScreenSize({
				innerHeight: window.innerHeight,
			})
			setStyle({
				marginTop: `${window.innerHeight - 500}px`,
			})
		}
	}, [screenSize])

	useEffect(() => {
		window.addEventListener('resize', setDimension)
		return () => {
			window.removeEventListener('resize', setDimension)
		}
	}, [screenSize])
	useEffect(() => {
		if (typeof window !== undefined) {
			setUrlPath(window.location.pathname)
		}
	}, [])



	// const calculateHeight = () => {
	// 	if (typeof document != 'undefined') {
	// 		const ele: any = document.getElementById('helloSection')
	// 		if (ele) {

	// 			if (ele.style.display == 'block') {
	// 				return navData.navBarHeight + 'px'
	// 			} else {
	// 				return '0px'
	// 			}


	// 		}
	// 	} else {
	// 		console.log('1');

	// 		if (navData.helloBarStatus) {
	// 			return navData.navBarHeight + 'px'
	// 		} else {
	// 			return '0px'
	// 		}
	// 	}

	// }
	const specialPaths = [
		'/resources/events/xccelerate-roadshow-series',
		'/innovation-keynote',
		'/middle-east/register-your-interest/sara',
		'/register-your-interest/innovation-keynote',
		'/pycon/hyderabad',
	];

	const imageUrl = (specialPaths.includes(urlPath) || urlPath.startsWith('/resources/customer-testimonials/'))
		? "https://storage.googleapis.com/staging-incrowd/png/Logos/1690305597827/file.png?updated_at=2023-07-25T17:19:58.402Z"
		: "https://storage.googleapis.com/infinity_bucket_static/staticfiles/image/site-logo/innovaccer-logo-black.svg";


	return (
		<div
			id="innoNav"
			// className={navData.helloBarStatus ? 'top-0' : 'top-88'}
			style={{
				// top: calculateHeight()
				top: navData.helloBarStatus == 'none' ? '0px' : navData.navBarHeight + 'px',
			}}>
			<div className="z-contain">
				<div className="z-row">
					<div className="z-item-12 mt-4 mb-4">
						<nav className="navbar navbar-expand-lg">
							<div className="topHeader">
								<a
									id="_link-innovaccer-logo"
									className="navbar-brand"
									href={redirectToHomepage()}
									rel="noreferrer">
									<img
										alt="logo"
										src={imageUrl}
										// src={urlPath == '/resources/events/xccelerate-roadshow-series' || urlPath == '/innovation-keynote' || urlPath == '/middle-east/register-your-interest/sara' || urlPath == '/register-your-interest/innovation-keynote' || urlPath == '/pycon/hyderabad' ? "https://storage.googleapis.com/staging-incrowd/png/Logos/1690305597827/file.png?updated_at=2023-07-25T17:19:58.402Z" : "https://storage.googleapis.com/infinity_bucket_static/staticfiles/image/site-logo/innovaccer-logo-black.svg"}
										width={'132'}
									// height={32}
									/>
								</a>
								{showNavControl && (
									<button
										className="navbar-toggler"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target=".collapse.navbar-collapse"
										aria-controls=".collapse.navbar-collapse"
										aria-expanded="false"
										aria-label="Toggle navigation"
										onClick={() => {
											setToggleIcon(!toggleIcon)
										}}>
										{!toggleIcon ? (
											<span className="material-icons deepest-blue--800 fs-30">
												menu
											</span>
										) : (
											<span className="material-icons deepest-blue--800 fs-30">
												close
											</span>
										)}
									</button>
								)}

								{!isEmpty(navData.navData) && showNavControl && (
									<div
										className="collapse navbar-collapse"
										id="mainHeaderInnovaccer">
										<ul className="navbar-nav ml-auto hide-on-mobile hide-on-tab">
											<NavDesktop navData={navData.navData} />
											<li>
												<div className="nav-link-btn">
													{navData.buttons && (
														<Button
															width={navData.buttons[0].width}
															type={navData.buttons[0].type}
															label={navData.buttons[0].label}
															icon={navData.buttons[0].icon}
															onClick={navData.buttons[0].buttonHandler}
														/>
													)}
												</div>
											</li>
										</ul>
									</div>
								)}
							</div>
						</nav>
					</div>
				</div>
			</div>
			<div className="show-on-mobile show-on-tab" style={{ width: '100%' }}>
				{toggleIcon && (
					<div className="" id="mainHeaderInnovaccer2">
						<ul
							className="navbar-nav ml-auto"
							style={{ height: `${screenSize?.innerHeight - navData.navBarHeight}px` }}>
							{navData.navData[0].attributes.region != 'me' && <NavMob navData={navData.navData} />}
							<li>
								<div className="mob-btn pl-25 pr-25">
									{navData.buttons && (
										<Button
											width={'100%'}
											type={navData.buttons[0].type}
											label={navData.buttons[0].label}
											icon={navData.buttons[0].icon}
											onClick={navData.buttons[0].buttonHandler}
										/>
									)}
								</div>
							</li>
						</ul>
					</div>
				)}
			</div>
		</div>
	)
}

export default Navbar
