/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import Button from '../button/button';
import isEmpty from 'lodash/isEmpty'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { redirect } from 'next/dist/server/api-utils'
import BulletPoints from '../BulletPoints/BulletPoints';

const buttonHandler = (type: string, redirectLink: string) => {
	if (type === 'redirect') window.open(redirectLink, '_self')
}

const NavDesktop = ({ navData }: any) => {
	const [activeMenu, setActiveMenu] = useState<any>({})
	const [activeMultliLinks, setActiveMultliLinks] = useState<any>({
		name: "",
		status: false
	})
	const handleMultliLinks = (status: boolean, name: string) => {
		const _obj = {
			name: name,
			status: status
		}
		setActiveMultliLinks({ ..._obj })

	}
	useEffect(() => {
		if (navData) {
			let obj: any = {}
			navData.map((ele: any) => {
				obj[ele.attributes.headerName] = ele.attributes.menuItems[0]?.name
			})

			setActiveMenu(obj)
		}
	}, [navData])
	// console.log(navData)

	return (
		<>
			{navData.map((navEle: any, idx: any) => (

				<li key={navEle.id} className="nav-item dropdown">
					<a
						className="nav-link fs-16 lh-24 dropdown-toggle"
						href="#"
						id={'mainHeaderSolutions' + navEle.id}
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false">
						{navEle.attributes.headerName}
					</a>
					<div
						className={`dropdown-menu double-column double-column-${idx + 1} ${navData[0].attributes.region === "uk" ? `double-column-uk-${idx + 1}` : ``}`}
						aria-labelledby={'mainHeaderSolutions' + navEle.id}>
						<div className="row ml-0"
							onMouseLeave={() => {
								setActiveMultliLinks(false)
							}}
						>
							<div className="col-md-3 item-1 pl-0 pr-0">
								<div className="leftLink">
									{!isEmpty(navEle.attributes.title) && (
										<div className="h5">{navEle.attributes.title}</div>
									)}
									{!isEmpty(navEle.attributes.description) && (
										<div
											className="fs-16 lh-22 mt-12"
											style={{ color: '#6D6D6D' }}
											dangerouslySetInnerHTML={{
												__html: navEle.attributes.description,
											}}></div>
									)}

									{!isEmpty(navEle.attributes.button.data?.attributes) && (
										<div className="mt-24">
											<Button
												width={
													navEle.attributes.button.data.attributes.Button.width
												}
												type={
													navEle.attributes.button.data.attributes.Button.type
												}
												label={
													navEle.attributes.button.data.attributes.Button.label
												}
												icon={
													navEle.attributes.button.data.attributes.Button.icon
												}
												onClick={() =>
													buttonHandler(
														navEle.attributes.button.data.attributes.action,
														navEle.attributes.button.data.attributes.actionValue
													)
												}
											/>
										</div>
									)}
								</div>
								{navEle.attributes.withSubNav && (
									<>
										{navEle.attributes.menuItems.map((subNav: any) => (
											<div
												key={subNav.id}
												className={cx('subMenu d-flex justify-space-between', {
													activeSubMenu:
														activeMenu[navEle.attributes.headerName] ===
														subNav.name,
												})}
												onMouseEnter={(e) => {
													let _obj = activeMenu
													_obj[navEle.attributes.headerName] = subNav.name
													setActiveMultliLinks(false)
													setActiveMenu({ ...activeMenu, _obj })
												}}>
												<div className="fs-16 lh-24">{subNav.name}</div>
												<div
													className="arrow"
													style={{
														borderLeft:
															activeMenu[navEle.attributes.headerName] ===
																subNav.name
																? '8px solid #ffffff'
																: '8px solid #19202e',
													}}></div>
											</div>
										))}
									</>
								)}
							</div>
							<div className="col-md-5 pl-0 pr-0 item-2">
								<div className="middleLink">
									{navEle.attributes.menuItems.map((ele: any) => (
										<div key={ele.id}>
											{activeMenu[navEle.attributes.headerName] == ele.name &&
												navEle.attributes.headerName == 'Company'
												? ele.allNavs.map((nav: any, index: number) =>
													index === 0 ? (
														<div
															key={nav.id}
															className="navCompanyCard pt-16"
															onMouseEnter={() => {
																if (nav.multi) {
																	setActiveMultliLinks(true)
																}
															}}
														// onMouseLeave={() => {
														// 	if (nav.multi) {
														// 		setActiveMultliLinks(false)
														// 	}
														// }}
														>
															<div className="fs-16 lh-22 navCompanyTitle font-dark">
																<span>
																	{nav.head}
																</span>
																{
																	nav.multi &&
																	<span className='multi-arrow'>
																		arrow
																	</span>
																}
															</div>
															<div className="fs-14 lh-20 navCompanyBody">
																{nav.description}
															</div>

														</div>
													) : (
														<div key={nav.id} className="pt-16 pl-16">
															<a href={nav.url} rel="noreferrer">
																<BulletPoints
																	items={[nav.head]}
																	link={true}
																	size={'s'}
																/>
															</a>
														</div>
													)
												)
												: activeMenu[navEle.attributes.headerName] ==
												ele.name &&
												ele.allNavs.map((nav: any) => (
													<div key={nav.id} className="navCard pt-16"
														onMouseEnter={() => {
															if (nav.multi) {
																handleMultliLinks(true, nav.head)

															}
															else {
																setActiveMultliLinks(false)
															}
														}}

														onMouseLeave={() => {
															!nav.multi &&
																setActiveMultliLinks(false)
														}}
													>
														<a href={nav.url} rel="noreferrer" >
															<div className="fs-16 lh-22 navTitle font-dark">
																<span>
																	{nav.head}
																</span>

																{
																	nav.multi &&
																	<span className='multi-arrow'>
																		{" "}
																	</span>
																}
															</div>
															<div className="fs-14 lh-20 navBody">
																{nav.description}
															</div>

														</a>
													</div>
												))}
										</div>
									))}
								</div>
							</div>
							<div className="col-md-4">
								<div className="rightLink">
									{navEle.attributes.menuItems.map((ele: any) => (
										<div key={ele.id}>
											{
												activeMultliLinks?.status ?
													<>
														{
															activeMenu[navEle.attributes.headerName] == ele.name && (
																<>

																	{
																		ele?.allNavs?.map((element: any, inx: number) => (
																			element?.head === activeMultliLinks?.name &&
																			<>

																				{ele?.allNavs[inx]?.multi_links?.map((nav: any) => (

																					<a
																						className='multi-links'
																						key={nav.title}
																						href={nav.url}>

																						{/* <img src={nav.icon.data.attributes.url} className='multi-links-img' alt="" /> */}
																						<div className='multi-links-div'>
																							<div className='multi-links-title'>
																								{nav.title}
																							</div>


																							<div className='multi-links-desc'>
																								{nav.desc}
																							</div>
																						</div>







																					</a>
																				))

																				}
																				{

																					<div className='mt-24'
																						style={{
																							'padding': '16px'
																						}}
																					>
																						<Button
																							type={"link"}
																							label={ele?.allNavs[inx]?.head}
																							onClick={() =>
																								window.open(ele?.allNavs[inx]?.url, "_self")
																							}
																						/>
																					</div>

																				}

																			</>

																		))
																	}


																</>


															)
														}

													</>

													:

													activeMenu[navEle.attributes.headerName] == ele.name && (
														<>
															<div className="head fs-16 lh-20">
																{ele?.quickLinks?.name}
															</div>
															{ele?.quickLinks?.allLinks.map((nav: any) => (
																<div key={nav.id} className="navCard">
																	{!isEmpty(nav.type) && (
																		<div className="navTitle fs-10 lh-14 font-dark">
																			{nav.type.replace('_', ' ')}
																		</div>
																	)}
																	<a
																		className="-link navContent fs-14 lh-20"
																		href={nav.url}>
																		{nav.content}
																	</a>
																</div>
															))}
															{!isEmpty(
																ele?.quickLinks?.button?.data?.attributes
															) && (
																	<div className="mt-44">
																		<Button
																			width={
																				ele.quickLinks.button?.data?.attributes
																					?.Button.width
																			}
																			type={
																				ele.quickLinks.button?.data?.attributes
																					?.Button.type
																			}
																			label={
																				ele.quickLinks.button?.data?.attributes
																					?.Button.label
																			}
																			icon={
																				ele.quickLinks.button?.data?.attributes
																					?.Button.icon
																			}
																			onClick={() =>
																				buttonHandler(
																					ele.quickLinks.button?.data?.attributes
																						?.action,
																					ele.quickLinks.button?.data?.attributes
																						?.actionValue
																				)
																			}
																		/>
																		{/* <ButtonLink
																size={'large'}
																label={ele.quickLinks.button.label}
																externalLink={
																	ele.quickLinks.button.redirectLink
																}
															/> */}
																	</div>
																)}
														</>
													)}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</li >
			))}
		</>
	)
}

export default NavDesktop
