import React from 'react'
import Bullet from './Bullet.jsx'
import propTypes from 'prop-types'

const BulletPoints = ({ items, link, size }) => {
	return (
		<div className="bullet-points">
			{items.map((item, idx) => {
				return (
					<div key={item + idx}>
						<div className={`bullet ${size} body-${size}`}>
							<div>
								<Bullet link={link} size={size} />
							</div>
							<div className={`${link ? ' -link' : ''} ml-12`}>{item}</div>
						</div>
						<br />
					</div>
				)
			})}
		</div>
	)
}

BulletPoints.propTypes = {
	items: propTypes.array.isRequired,
	link: propTypes.bool.isRequired,
	size: propTypes.oneOf(['l', 'm', 's', 'xs']),
}

BulletPoints.defaultProps = {
	items: [
		'Referral Management',
		'Contact Center',
		'Patient Relationship Management',
		'Provider Cloud Data Platform',
	],
	link: false,
	size: 'l',
}

export default BulletPoints
