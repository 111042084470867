import React from 'react'
import PropTypes from 'prop-types'

export const PlayButton = ({ type, label, onClick }) => {
	return (
		<div className="playButton">
			<button
				type="submit"
				onClick={onClick}
				className={['button', `button-type--${type}`].join(' ')}
			>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
					<mask id="mask0_769_490" maskUnits="userSpaceOnUse" x="0" y="0">
						<rect width="32" height="32" fill="#D9D9D9" />
					</mask>
					<g mask="url(#mask0_769_490)">
						<path
							className="playCircle"
							d="M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z"
							stroke="#19202E"
							stroke-width="2"
						/>
						<path
							className="playButtonIcon"
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M22.3633 15.1297L14.4559 9.92271C14.1347 9.71125 13.723 9.69263 13.3839 9.87423C13.0449 10.0558 12.8333 10.4083 12.8333 10.7918L12.8333 21.2082C12.8333 21.5917 13.0449 21.9442 13.3839 22.1258C13.723 22.3074 14.1347 22.2887 14.4559 22.0773L22.3657 16.8703C22.6582 16.6764 22.8338 16.3494 22.8333 15.9993C22.8328 15.6493 22.6563 15.3228 22.3633 15.1297Z"
							fill="#19202E"
						/>
					</g>
				</svg>
				{'  '}
				<span className="labeling">{label}</span>
			</button>
		</div>
	)
}

PlayButton.propTypes = {
	type: PropTypes.oneOf(['soft', 'outline']),

	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
}

// PlayButton.defaultProps = {
// 	type: 'main',
// 	label: 'Button',
// 	onClick: undefined,
// }

export default PlayButton
