import { Button } from '../../button/button'

const CookieModal2 = (props: any) => {
	console.log(props)
	return (
		<div className="flex-container">
			<i
				className="material-icons-round cancel-button"
				style={{ display: 'block' }}
				onClick={() => {
					props.onSelectDefaultPreference()
				}}>
				close
			</i>
			<div className="message">
				<div className="body-xs">
					By clicking “Allow all cookies” you agree to the storing of cookies on your device to enhance site navigation, analyse site usage, and assist in our marketing efforts. If you continue to browse this site without any selection, you agree to the storage of only strictly necessary cookies. For more information, read our&nbsp;
					<a
						className="cookie-policy  -link"
						target="_blank"
						href="/privacy#cookies-tracking">
						Cookie Policy.
					</a>
				</div>
			</div>
			<div className="preferences-btn">
				<Button
					type={'outline'}
					icon={'none'}
					width={'100%'}
					label={'Cookie preferences'}
					onClick={() => {
						props.onSelectPreferences()
					}}
				/>
			</div>
			<div className="allow-cookie-btn">
				<Button
					type={'main'}
					icon={'none'}
					width={'100%'}
					label={'Allow all cookies'}
					onClick={() => {
						props.onAcceptAll()
					}}
				/>
			</div>
		</div>
	)
}

export default CookieModal2
