import React from 'react'
import PropTypes from 'prop-types'

const ButtonLink = ({ label, onClick }) => {
	return (
		<div className="Button">
			<button
				cursor="pointer"
				onClick={onClick}
				className={['button', 'button-type--link'].join(' ')}
			>
				{label}
				<svg
					viewBox="0 0 10 10"
					width="12"
					height="12"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M9.6 0H0.965685C0.609324 0 0.430856 0.430856 0.682842 0.682842L9.31716 9.31716C9.56914 9.56914 10 9.39068 10 9.03431V0.4C10 0.179086 9.82091 0 9.6 0Z" />
				</svg>
			</button>
		</div>
	)
}

ButtonLink.propTypes = {
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
}

ButtonLink.defaultProps = {
	label: 'Button Link',
	externalLink: '/',
}

export default ButtonLink
