import { gql } from '@apollo/client'

const categoryWiseTrackers = gql`
	query {
		trackerMetas {
			data {
				attributes {
					cookieCategory
					trackers {
						data {
							attributes {
								script
							}
						}
					}
				}
			}
		}
	}
`
export default categoryWiseTrackers
