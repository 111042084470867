import clientForm from './gql/apolloProd'
import cookieControl from './gql/cookieControl'
import trackerMeta from './gql/trackerMeta'
import categoryWiseTrackers from './gql/categoryWiseTrackers'

interface Tracker {
	type: string
	value: string
}

export const getCookieControlData = async (region: string) => {
	const effectiveRegion = region || 'default'

	const { data: trackerMetaData } = await clientForm.query({
		query: cookieControl,
	})

	const tempData = await trackerMetaData?.trackerMetas?.data

	return tempData
		.filter((el: any) => {
			// If el.attributes.region is null, treat it as 'default'
			const regionToCompare = el.attributes.region || 'default'
			return regionToCompare === effectiveRegion
		})
		.map((el: any) => {
			return el.attributes.cookieCategory
		})
}

export const getTrackerMeta = async (region: string) => {
	const effectiveRegion = region || 'default'

	const { data: trackerMetaData } = await clientForm.query({
		query: trackerMeta,
	})
	const tempData = await trackerMetaData?.trackerMetas?.data
	return tempData
		.filter((el: any) => {
			// If el.attributes.region is null, treat it as 'default'
			const regionToCompare = el.attributes.region || 'default'
			return regionToCompare === effectiveRegion
		})
		.map((el: any) => {
			let { cookieCategory, cookieDescription, cookies } = el.attributes
			return {
				cookieCategory: cookieCategory,
				cookieDescription: cookieDescription,
				cookies: cookies,
			}
		})
}

const createScriptFromUrl = (url: string) => {
	const script = document.createElement('script')
	script.src = url
	document.head.appendChild(script)
}
const createScriptWithCode = (code: string) => {
	const script = document.createElement('script')
	script.type = 'text/javascript'
	script.text = code
	document.head.appendChild(script)
}

const fetchTrackerData = async (cookieControlValue: any) => {
	let trackerData: any = []
	const { data: rawData } = await clientForm.query({
		query: categoryWiseTrackers,
	})
	let trackerMeta = await rawData?.trackerMetas?.data
	for (let meta of trackerMeta) {
		if (cookieControlValue[meta.attributes.cookieCategory]) {
			for (let data of meta.attributes.trackers.data) {
				trackerData.push(data.attributes.script)
			}
		}
	}
	return trackerData
}

const filterTrackerData = (trackerData: any[]) => {
	/**
	 * creates array of individual tracker scripts tags that needs to be mounted
	 * @param trackerData array of string returned from api
	 * @return array of strings containing individual script tags
	 */
	const extractScripts = (rawString: string) => {
		const pattern = /<script[\s\S]*?>[\s\S]*?<\/script>/gi
		return rawString.match(pattern)
	}
	let filteredTrackerData: any[] = []
	trackerData.forEach((tracker: any) => {
		filteredTrackerData = filteredTrackerData.concat(extractScripts(tracker))
	})
	return filteredTrackerData
}

const processTrackerData = (filteredTrackerData: string[]) => {
	/**
	 * Creates Json out of filtered tracker data
	 * @param filteredTrackerData array of strings containing individual script tags
	 * @return @type {Tracker[]} array of tracker type object
	 */
	const processedTrackerData: Tracker[] = []
	const pattern1 = /<script.*?src="(.*?)"/
	filteredTrackerData.forEach((el: string) => {
		let temp = el.match(pattern1)
		if (temp) {
			processedTrackerData.push({ type: 'url', value: temp[1] })
		} else {
			processedTrackerData.push({
				type: 'code',
				value: el.replace(/(<([^>]+)>)/gi, ''),
			})
		}
	})
	return processedTrackerData
}

const mountTrackers = async (cookieControlValue: any) => {
	/**
	 * Fetches Tracker Data, Processes Tracker Data and mount selected trackers scripts
	 * @param pageProps
	 * @return
	 */
	const trackerData = await fetchTrackerData(cookieControlValue)
	let filteredTrackerData = filterTrackerData(trackerData)
	let processedTrackerData = processTrackerData(filteredTrackerData)
	processedTrackerData.forEach(({ type, value }) => {
		console.log(type)
		if (type === 'url') {
			createScriptFromUrl(value)
		} else {
			createScriptWithCode(value)
		}
	})
	return 0
}

export default mountTrackers
